<template>
	<div class="">
		<h6>{{ program.name }}</h6>

		<div class="">
			<div class="justify-space-between">
				<div class="flex-1 bg-white mr-10">
					<div class="pa-10">
						<h6 class="under-line-identify">신청 구분</h6>
						<table class="table th-left td-left">
							<col class="width-120px">
							<col class="width-50-120">
							<col class="width-120px">
							<col class="width-50-120">
							<tbody>
								<tr>
									<th>영업단 구분 <span class="color-red">*</span></th>
									<td>
										<select
											v-model="item.type"
											class="pa-5-10 "
											@change="resetUpper"
											disabled
										>
											<template
												v-for="(agency, index) in codes.agency_type"
											>
												<option
													v-if="index > 0 && index <= codes.agency_type.length - 2"
													:key="'agency_' + index"
													:value="agency.code"
												>{{ agency.name }}</option>
											</template>
										</select>
									</td>
									<th>상위 영업단 <span class="color-red">*</span></th>
									<td>
										<div class="justify-space-between">
											<div class="flex-column justify-center">{{ item.upperName ? item.upperName : upper_name }}</div>
											<button
												v-if="item.type > 1"
												v-show="false"
												class="bg-identify pa-5-10 vertical-middle"
												@click="getAgencyList"
											>영업단 조회</button>
										</div>
									</td>
								</tr>
								<tr>
									<th>아이디</th>
									<td>{{ item.id }}</td>
									<th>비밀번호</th>
									<td class="text-right">
										<div class="justify-space-between">
											<span class=""></span>
											<button
												class="bg-identify pa-5-10"
												@click="isPassword"
											>임시 비밀번호 발급</button>
										</div>
									</td>
								</tr>
								<tr>
									<th>등록일자</th>
									<td>{{ item.date }}</td>
									<th>계정상태</th>
									<td>
										<div class="justify-space-between">
											<span class="flex-column justify-center">{{ state_name }}</span>
											<button
												class="bg-identify pa-5-10 vertical-middle"
												@click="isStatus"
											>계정 상태 변경</button>
										</div>
									</td>
								</tr>

								<tr v-if="item.type == 1">
									<th>수기결제 MID</th>
									<td class="">
										<div class="justify-space-between">

											<span class="justify-center">{{ item.manualPaymentId ? '사용중' : '미사용'}}</span>
											<button
												class="pa-5-10 bg-identify"
												@click="isModalMid"
											>편집</button>
										</div>
									</td>
									<th>빌키결제 MID</th>
									<td class="">
										<div class="justify-space-between">
											<span class="justify-center">{{ item.billKeyPaymentId ? '사용중' : '미사용'}}</span>
											<button
												class="pa-5-10 bg-identify"
												@click="isModalMid"
											>편집</button>
										</div>
									</td>
								</tr>
								<tr v-if="item.type == 1">
									<th>IV</th>
									<td class="">
										<div class="justify-space-between">

											<span class="justify-center">-</span>
											<button
												class="pa-5-10 bg-identify"
												@click="isModalIV"
											>편집</button>
										</div>
									</td>
									<th>KEY</th>
									<td class="">
										<div class="justify-space-between">
											<span class="justify-center">-</span>
											<button
												class="pa-5-10 bg-identify"
												@click="isModalIV"
											>편집</button>
										</div>
									</td>
								</tr>
								<tr v-if="item.type == 1">

									<th>SID</th>
									<td class="">

										<div class="justify-space-between">
											<span class="justify-center">{{ item.sid }}</span>
											<button
												class="pa-5-10 bg-identify"
												@click="isModalIV"
											>편집</button>
										</div>
									</td>
									<th>채번</th>
									<td>
										<div  class="justify-space-between items-center">
											{{ account }}
											<button
												class="pa-5-10 bg-identify"
												@click="postAccount"
											>재발급</button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="flex-1">
					<div class="bg-white pa-10">
						<h6 class="under-line-identify">사업자 정보</h6>
						<table class="table th-left td-left">
							<col width="120px">
							<col width="auto">
							<col width="120px">
							<col width="auto">
							<tbody>
							<tr>
								<th>사업자 구분 <span class="color-red">*</span></th>
								<td colspan="3">
									<label
										class="cont-radio"
										v-for="code in codes.P02"
										:key="code.TOTAL_CODE">
										<input
											v-model="item.businessType"
											type="radio"
											:value="code.TOTAL_CODE"
											class="box"
										/>
										{{ code.code_name }}
									</label>
								</td>
							</tr>
							<tr
								v-if="item.businessType == '1'"
							>
								<th>사업자등록번호 <span class="color-red">*</span></th>
								<td colspan="3">
									<input
										type="number"
										v-model="item.businessNumber"
										:ruels="[rules.max(item, 'businessNumber', 15)]"
										class="input-box-inline"
										placeholder="사업자 등록 번호"
									/>
								</td>
							</tr>
							<tr
								v-if="item.businessType == '0'"
							>
								<th>사업자등록번호 <span class="color-red">*</span></th>
								<td>
									<input
										type="number"
										v-model="item.businessNumber"
										:ruels="[rules.max(item, 'businessNumber', 15)]"
										class="input-box"
										placeholder="사업자 등록 번호"
									/>
								</td>
								<th>법인등록번호 <span class="color-red">*</span></th>
								<td >
									<input
										type="number"
										v-model="item.corporationNumber"
										:ruels="[rules.max(item, 'corporationNumber', 15)]"
										class="input-box"
										placeholder="법인 등록 번호"
									/>
								</td>
							</tr>
							<tr
								v-if="item.businessType == '2'"
							>
								<th>주민등록 번호 <span class="color-red">*</span></th>
								<td colspan="3">
									<input
										v-model="item.frontSSN"
										type="number"
										:ruels="[rules.max(item, 'frontSSN', 6)]"
										class="input-box-inline mr-10"
										placeholder="주민등록 앞번호"
									/>
									<input
										v-model="item.backSSN"
										type="password"
										maxlength="7"
										class="input-box-inline"
										placeholder="주민등록 뒷번호"
									/>
								</td>
							</tr>
							<tr>
								<th>상호명 <span class="color-red">*</span></th>
								<td colspan="3">
									<input
										type="text"
										v-model="item.shopName"
										maxlength="50"
										class="input-box"
										placeholder="상호명을 입력하세요"
									/>
								</td>
							</tr>
							<tr>
								<th>대표자 성명 <span class="color-red">*</span></th>
								<td>
									<input
										type="text"
										v-model="item.name"
										maxlength="20"
										class="input-box"
										placeholder="대표자 성명을 입력하세요"
									/>
								</td>
								<th>휴대폰 번호</th>
								<td>
									<input
										type="number"
										v-model="item.hp"
										:ruels="[rules.max(item, 'hp', 12)]"
										class="input-box"
										placeholder="대표자 휴대폰번호를 입력하세요"
									/>
								</td>
							</tr>
							<tr>
								<th>업태</th>
								<td class="si-desc"
									style="margin-right: 10px;">
									<input
										type="text"
										v-model="item.businessCondition"
										maxlength="20"
										class="input-box"
										placeholder="업태를 입력하세요"
									/>
								</td>
								<th>업종</th>
								<td>
									<input
										type="text"
										v-model="item.businessLine"
										maxlength="20"
										class="input-box"
										placeholder="업종을 입력하세요"
									/>
								</td>
							</tr>
							<tr>
								<th>사업장 주소 <span class="color-red">*</span></th>
								<td colspan="3">

									<input
										v-model="item.zipCode"

										class="input-box-inline mr-10"
										type="text" placeholder="우편번호"
										readonly

										@click="daumPost('company')"
									>

									<button
										class="box pa-5-10 bg-identify"
										@click="daumPost('company')"
									>주소 검색</button>

									<input
										v-model="item.address"

										class="input-box full-width mt-10" type="text" placeholder="주소"
										readonly

										@click="daumPost('company')"
									>
									<input
										v-model="item.addressDetail"

										class="input-box full-width mt-10" type="text" placeholder="상세주소를 입력하세요."
										maxlength="50"
									>
								</td>
							</tr>
							</tbody>
						</table>
					</div>

				</div>
			</div>
			<div class="justify-space-between">
				<div class="flex-1 mr-10">
					<div class="mt-10 bg-white pa-10">
						<div
							class="under-line-identify justify-space-between"
						>
							<h6>서비스 이용 조건</h6>
							<span class="flex-column justify-end size-px-12">수수료는 소수점 두자리, 부가세는 별도입니다.</span>
						</div>

						<table class="table th-left td-left">
							<col width="120px" />
							<col width="auto" />
							<tbody>
							<tr>
								<th>서비스 수수료 <span class="color-red">*</span></th>
								<td>
									<div class="justify-space-between">
										<div class="flex-1">
											상위 영업점 수익률
											<input v-model="item.upperFee" class="input-box-inline bg-identify text-right width-50px color-eee" readonly :rules="[rules.demical(item, 'upperFee', { min: 2, max: 2})]"/> %
										</div>
										<div class="flex-2">
											수익률
											<input
												v-model="item.fee" class="input-box-inline width-50px text-right" @change="checkFee()"
												:rules="[rules.demical(item, 'fee', { min: 2, max: 2})]"/> %
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<th>정산기준</th>
								<td class="text-left">위 에이전트는 PG사와의 계약관계에 근거하여 정산금을 결제대행 서비스를 통하여 지급받는 것에 동의합니다.</td>
							</tr>
							<tr>
								<th>정산주기</th>
								<td class="text-left">영업일 기준 / 월 정산</td>
							</tr>
                            <tr v-if="item.type == 1">
                                <th>배달비 지급 주기</th>
                                <td>D-{{ item.quickType }}</td>
                            </tr>
							<tr>
								<th>특약사항</th>
								<td>
									<ol class="text-left line-height-200">
										<li>1. 이행보험증 증권은 서비스 이용 신청일로부터 7일 이내에 제출한다.</li>
										<li>2. 정산 후 취소 시 발생하는 모든 제반 비용은 상기 에이전트가 책임을 진다.</li>
										<li>3. 단말기 분실 및 파손 시 통신비 1년 약정에 대한 비용은 상기 에이전트가 책임을 진다.</li>
										<li>4. 상기 에이전트는 하위 가맹점의 부실채권에 대해 공동의 책임을 진다.</li>
										<li>5. 상기 에이전트는 본사와의 계약기간동안 모집된 가맹점에 관하여 상호간의 협의없이 타결제 대항사와 에이전트 계약을 할 수 없다.</li>
									</ol>
								</td>
							</tr>
							</tbody>
						</table>

					</div>
				</div>
				<div class="flex-1">
					<div class="mt-10 bg-white pa-10">
						<h6>정산 계좌 정보</h6>
						<table class="table th-left td-left">
							<col width="120px" />
							<col width="auto" />
							<col width="120px" />
							<col width="auto" />
							<tbody>
							<tr>
								<th>은행 <span class="color-red">*</span></th>
								<td>
									<select
										v-model="item.bankIdx"
										class="input-box"
									>
										<option value="">선택하세요</option>
										<option
											v-for="(code, index) in codes.P13"
											:key="'bank_' + index"
											:value="code.idx"
										>{{ code.name}}</option>
									</select>
								</td>
								<th>계좌번호 <span class="color-red">*</span></th>
								<td>
									<input
										v-model="item.account"
										type="number"
										class="input-box full-width"
										:rules="[rules.max(item, 'bank_name', 25)]"
										placeholder="정산 계좌 번호"
									/>
								</td>
							</tr>
							<tr>
								<th>예금주 <span class="color-red">*</span></th>
								<td>
									<input
										v-model="item.accountHolder"
										class="input-box full-width"
										placeholder="정산 계좌 예금주"
										maxlength="15"
									/>
								</td>
								<th>이메일 <span class="color-red">*</span></th>
								<td>
									<input
										v-model="item.email"
										type="email"
										class="input-box full-width"
										placeholder="세금계산서 발행 이메일"
										maxlength="50"
									/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					<div class="mt-10 bg-white pa-10">
						<h6>부가정보</h6>
						<table class="table th-left td-left full-width">
							<col width="120px" />
							<col width="auto" />
							<tbody>
							<tr>
								<th>초기비용</th>
								<td>
									<div> 보증보험 증권
										<input
											v-model="item.performanceBondAmount"
											class="box width-10 pa-5-10 text-right"
											type="number"
											:rules="[rules.max(item, 'performanceBondAmount', 7)]"
										/> 만원
									</div>
									<p class="mt-10">기타 관리비: 단말기 및 통신비는 해당 PG사의 정책에 따라 별도 부가됨</p>
								</td>
							</tr>
							<tr>
								<th>계약서류</th>
								<td>
									<label class="box pa-5-10 vertical-middle mr-10 mb-10">
										<input
											v-show="false"
											type="file"
											multiple
											accept="image/*"
                      @change="setFile"
										/>
										<v-icon
											small
										>mdi mdi-image-multiple</v-icon> 첨부파일
									</label>
                  <div class="mt-10">
                    <button
                        v-for="(file, index) in item.attachments"
                        :key="'file_delete_' + index"
                        @click="isDeleteNewFile(index)"
                        class="btn-default pa-5-10 mr-10 mb-10"
                    >{{ file.name }} <v-icon class="color-red" small>mdi mdi-close</v-icon></button>
                  </div>
									<template
										v-for="(file, index) in files"
									>
										<button
											:key="'file_' + index"
											class="btn-default pa-5-10 mb-10"
											@click="download(file)"
										>{{ file.originFileName }} <v-icon small>mdi mdi-download</v-icon></button>
										<button
											:key="'file_delete_' + index"
											@click="isDeleteFile(index)"
											class="box pa-5 mr-10 mb-10"
										><v-icon class="color-red" small>mdi mdi-close</v-icon></button>
									</template>
								</td>
							</tr>
              <tr v-if="item.type == 1">
                <th>로고이미지</th>
                <td>
                  <label class="box pa-5-10 vertical-middle mr-10 mb-10">
                    <input
                        v-show="false"
                        type="file"
                        accept="image/*"
                        @change="setLogoFile"
                    />
                    <v-icon
                        small
                    >mdi mdi-image-multiple</v-icon> 첨부파일
                  </label>
                  <div class="mt-10">
                    <button
                        v-if="item.logoImage[0]"
                        @click="item.logoImage = []"
                        class="btn-default pa-5-10 mr-10 mb-10"
                    >{{ item.logoImage[0].name }} <v-icon class="color-red" small>mdi mdi-close</v-icon></button>
                  </div>
                  <template
                      v-if="logo_files"
                  >
                    <button
                        class="btn-default pa-5-10 mb-10"
                        @click="download(logo_files[0])"
                    >{{ logo_files[0].name }} <v-icon small>mdi mdi-download</v-icon></button>
                    <button
                        @click="modifyLogoFile"
                        class="box pa-5 mr-10 mb-10"
                    ><v-icon class="color-red" small>mdi mdi-close</v-icon></button>
                  </template>
                </td>
              </tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div
			class="mt-10 bg-white pa-10"
		>
			<h6 class="under-line-identify">수정 이력</h6>
			<ul
				v-if="history_list.length > 0"
			>
				<li
					v-for="(history, index) in history_list"
					:key="'history_' + index"

				>{{ history.date }} {{ history.cotnent }}</li>
			</ul>
			<div
				v-else
				class="pa-50 text-center"
			>No Data</div>
		</div>

		<div
			class="ptb-30 justify-center"
		>
			<button
				class="bg-identify pa-10-20 mr-10"
				@click="save"
			>저장</button>
			<button
				class="bg-ddd pa-10-20"
				@click="toList"
			>목록</button>
		</div>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"
			:top="true"
			:bottom="true"

			title="임시 비밀번호 발급"
			content="임시 비밀번호를 발급하고 등록된 연락처로 전송하시겠습니까?"
			width="360px"
			content_class="pa-30-10 text-left"

			@close="clear"
			@click="doPassword"
			@cancel="clear"
		>
		</Modal>

		<Modal
			:is_modal="is_modal2"
			:option="modal_option2"

			title="영업점 계정상태 변경"
			:top="true"
			:bottom="true"
			width="360px"

			@close="clear"
			@click="doStatus"
			@cancel="clear"
		>
			<div
				slot="modal-content"
			>

				<select
					v-model="item.state"
					class="full-width pa-10"
				>
					<option
						v-for="(status, index) in codes.status_agency"
						:key="'status_' + index"
						:value="status.code"
					>{{ status.name }}</option>
				</select>

				<textarea
					class="mt-10"
					v-model="item.reason"
					placeholder="메모"
				></textarea>

			</div>
		</Modal>

		<Modal
			:is_modal="is_modal3"
			:option="modal_option3"
			@close="clear"
		>
			<div
				slot="modal-bottom"
				class="justify-space-between"
			>
				<button
					class="btn flex-1 btn-identify"
					@click="doFileDelete"
				>확인</button>
				<button
					class="btn flex-1 btn-default"
					@click="clear"
				>취소</button>
			</div>
		</Modal>

		<DaumPost
			:overlay="is_post"
			:config="daum_config"
			@callBack="addPost"
		></DaumPost>

		<Modal
			:is_modal="is_modal4"
			:option="modal_option4"
			:top="true"
			:bottom="false"

			title="상위 영업단 조회"
			height="550px"

			@close="clear"
		>
			<AgencySearchList
				slot="modal-content"

				:item_type="item.type"
				:user="user"

				@click="setUpper"
				@cancel="clear"
				@next="nextUpper"
			></AgencySearchList>
		</Modal>

		<Modal
			:is_modal="is_modal5"
			:option="modal_option5"
			@close="clear"
		>
			<div slot="modal-content">
				<img :src="item_att" alt="img" style="object-fit: cover; max-width: 600px; max-height: 800px;" />
			</div>
		</Modal>
		<Modal
			:is_modal="modal_mid"
			:top="true"
			:bottom="true"
			:slot_bottom="true"

			title="결제 MID 설정"
			width="420px"

			@close="clear"
		>
			<div
				slot="modal-content"
			>
				<table
					class="table th-left top-line-identify"
				>
					<col width="130px" />
					<col width="auto" />
					<tbody>
						<tr
							v-if="item.type == 1"
						>
							<th>수기결제 MID</th>
							<td class="">
								<input
									v-model="item.manualPaymentId"
									class="input-box full-width"
									placeholder="수기결제 MID"
									max="50"
								/>
							</td>

						</tr>

						<tr
							v-if="item.type == 1"
						>
							<th>수기결제 비밀번호</th>
							<td class="">
								<input
									v-model="item.manualPaymentPassword"
									type="password"
									class="input-box full-width"
									placeholder="수기결제 계정 비밀번호"
									max="50"
								/>
							</td>
						</tr>

						<tr
							v-if="item.type == 1"
						>
							<th>빌키결제 MID</th>
							<td class="">
								<input
									v-model="item.billKeyPaymentId"
									class="input-box full-width"
									placeholder="빌키결제 MID"
									max="50"
								/>
							</td>

						</tr>

						<tr
							v-if="item.type == 1"
						>
							<th>빌키결제 비밀번호</th>
							<td class="">
								<input
									v-model="item.billKeyPaymentPassword"
									type="password"
									class="input-box full-width"
									placeholder="빌키결제 계정 비밀번호"
									max="50"
								/>
							</td>
						</tr>
					</tbody>
				</table>

				<div class="mt-20 text-left"><label><input type="checkbox" /> MID 설정을 변경하시겠습니까?</label></div>
			</div>
			<div
				slot="modal-bottom"
				class="pa-10"
			>
				<button
					class="bg-identify-outline mr-10 pa-10"
					@click="isDeleteMid"
				>MID 삭제</button>
				<button
					class="bg-identify mr-10 pa-10"
					@click="doSaveMid"
				>수정</button>
				<button
					class="bg-bbb pa-10"
					@click="clear"
				>닫기</button>
			</div>
		</Modal>
		<Modal
			:is_modal="modal_delete_mid"
			:top="true"
			:bottom="true"

			title="결제 MID 삭제"
			content="사용중인 결제 MID 정보를 삭제하시겠습니까?"
			width="380px"
			content_class="ptb-30"

			@clear="clear"
			@click="doDeleteMid"
			@cancel="clear"
		></Modal>

		<Modal
			:is_modal="modal_iv"
			:top="true"
			:bottom="true"
			:slot_bottom="true"

			title="IV, KEY 설정"
			width="420px"

			@close="clear"
		>
			<div
				slot="modal-content"
			>
				<table
					class="table th-left top-line-identify"
				>
					<col width="130px" />
					<col width="auto" />
					<tbody>
					<tr
						v-if="item.type == 1"
					>
						<th>IV</th>
						<td class="">
							<input
								v-model="item.iv"
								class="input-box full-width"
								placeholder="IV"
								max="50"
							/>
						</td>

					</tr>

					<tr
						v-if="item.type == 1"
					>
						<th>KEY</th>
						<td class="">
							<input
								v-model="item.key"
								class="input-box full-width"
								placeholder="KEY"
								max="50"
							/>
						</td>
					</tr>

					<tr
						v-if="item.type == 1"
					>
						<th>SID</th>
						<td class="">
							<input
								v-model="item.sid"
								class="input-box full-width"
								placeholder="SID"
								max="50"
							/>
						</td>
					</tr>
					</tbody>
				</table>

			</div>
			<div
				slot="modal-bottom"
				class="pa-10"
			>
				<button
					class="bg-identify pa-10"
					@click="postIv"
				>저장</button>
			</div>
		</Modal>
	</div>
</template>

<script>
	import Modal from "@/components/Modal";
	import DaumPost from "@/components/Daum/DaumPost";
	import AgencySearchList from "@/view/Agency/AgencySearchList";

	export default {
		name: 'MerchantDetail'
		,
		components: {AgencySearchList, DaumPost, Modal},
		props: ['Axios', 'user', 'codes', 'rules']
		,data: function(){
			return {
				program: {
					name: '영업점 상세정보'
					, top: true
					, title: true
					, bottom: false
				}
				,fee_base: 4
				,item: {
					fee: 0,
				}
				,item_origin: {

				}
				,is_modal: false
				,modal_option: {

				}
				,is_modal2: false
				,modal_option2: {

				}
				,is_modal3: false
				,modal_option3: {
					title: '첨부파일 삭제'
					,top: true
					,bottom: true
					,content: '해당 첨부파일을 삭제하시겠습니까?'
					,content_class: 'pa-30-10 text-left'
					,width: '360px'
				}
				,is_modal4: false
				,modal_option4: {
				}
				,is_modal5: false
				,modal_option5: {
					title: '첨부파일'
					,top: true
					,bottom: false
				}
				,is_post: false
				,daum_config: {
					width: '360px'
				}
				,items_history: [

				]
				,agency_list: []
				,next_list: []
				,upperIdx: ''
				,upperFee: 0
				,availableFee: 0
				,lowerAvailableFee: 0
				,item_att: ''
				,files: []
				,logo_files: ''
        ,modifyLogo: false
				,deleteIdxList: []
				,modal_mid: false
				,modal_delete_mid: false
				, modal_iv: false
				, modal_key: false
				, account: ''
			}
		}
		,computed: {
			history_list: function (){
				return this.items_history.filter(function(item){
					return item
				})
			}
			,state_name: function(){

				let name = ''
				for(let i = 0; i < this.codes.status_agency.length; i++){
					if(this.item.state == this.codes.status_agency[i].code){
						name = this.codes.status_agency[i].name
						break
					}
				}

				return name
			}
			,upper_name: function(){
				let upper_name = ''
				if(this.item.type == 1){
					upper_name = '본사'
				}else{
					if(!this.item.upperIdx){
						upper_name = '영업단 구분을 선택하세요'
					}else{
						if(this.item.branchIdx) {
							upper_name = this.item.branchName
						}
						if(this.item.distributorIdx){
							upper_name = ' ' + this.item.distributorName
						}
						if(this.item.agencyIdx){
							upper_name = ' ' + this.item.agencyName
						}
						if(this.item.resellerIdx){
							upper_name = ' ' + this.item.resellerName
						}
					}
				}

				return upper_name
			}

		}
		, methods: {
			getData: async function(){
				try{
					this.$emit('onLoading')
					const result = await this.Axios({
						method: 'get'
						,url: 'branch/' + this.$route.params.idx
						,multipart: true

					})
					if(result.success){
						this.item = result.data
						this.files = this.item.attachments
            if(this.item.logoImage) {
              this.logo_files = []
              this.logo_files[0] = {
                'name': this.item.logoImageOriginName,
                'path': this.item.logoImage
              }
            }
						this.item.attachments = []
						this.item.logoImage = []
						this.item_origin = result.data
					}else{
						this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
					}
				}catch(e){
					console.log(e)
				}finally {
					this.$emit('offLoading')
				}
			}
			,getBankList: async function(){
				try{
					this.$emit('onLoading')
					let result = await this.Axios({
						method: 'get'
						, url: 'common/bank'
					})
					if(result.success){
						this.$set(this.codes, 'P13', result.data)
						this.$emit('setNotify', { type: 'error', message: result.message})
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(e){
					console.log(e)
				}finally {
					this.$emit('offLoading')
				}
			}
			,getAgencyList: async function(){
				this.is_modal4 = true
			}
      ,setFile: function(e) {
        console.log(this.item.attachments,11)

        for (let i = 0; i < e.target.files.length; i++){

          this.item.attachments.push(e.target.files[i])
        }
        console.log(this.item.attachments, 12)
      }
      ,setLogoFile: function(e) {
        console.log(e)
        if(e.target.files[0]){
          this.item.logoImage = []
          this.item.logoImage[0] = e.target.files[0]
          this.modifyLogoFile()
        }
        console.log(this.item.logoImage)
      }
      ,modifyLogoFile: function () {
        this.item.isDeleteLogoImage = true
        this.logo_files = ''
      }
			,save: async function(){
				try{

					if(this.item.businessType == this.codes.P02_0){
						this.item.frontSSN = ''
						this.item.backSSN = ''
					}else if(this.item.businessType == this.codes.P02_1) {
						this.item.corporationNumber = ''
						this.item.frontSSN = ''
						this.item.backSSN = ''
					}else if(this.item.businessType == this.codes.P02_2){
						this.item.businessNumber = ''
						this.item.corporationNumber = ''
					}
// 아무것도 안넣었을때 '' => 파라미터 안보내도록
//                     if ()
                    if (!this.item.logoImage) {
                        delete this.item.logoImage
                    }

					this.$emit('onLoading')
					const result = await this.Axios({
						method: 'put'
						,url: 'branch/' + this.$route.params.idx
						,data: this.item
            , multipart: true
					})
					if(result.success){
            this.getData()
						this.$emit('setNotify', { type: 'success', message: result.message})
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(e){
					console.log(e)
				}finally {
					this.$emit('offLoading')
				}
			}
			,isPassword: function(){
				this.clear()
				this.is_modal = true
			}
			,isStatus: function(){
				this.clear()
				this.is_modal2 = true
			}
			,clear: function(){
				this.is_modal = false
				this.is_modal2 = false
				this.is_modal3 = false
				this.is_modal4 = false
				this.is_modal5 = false
				this.modal_mid = false
				this.modal_delete_mid = false
				this.modal_iv = false
			}
			,doStatus:  async function(){
				try{
					this.$emit('onLoading')
					const result = await this.Axios({
						method: 'put'
						,url: 'branch/state/' + this.$route.params.idx
						,data: {
							state: this.item.state
							,reason: this.item.reason
						}
					})
					if(result.success){
						this.$emit('setNotify', { type: 'success', message: result.message})
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(e){
					console.log(e)
				}finally {
					this.clear()
					this.$emit('offLoading')
				}
			}
			,doPassword: async function(){
				try{
					this.$emit('onLoading')
					const result = await this.Axios({
						method: 'put'
						,url: 'branch/tempPassword/' + this.$route.params.idx
					})
					if(result.success){
						this.$emit('setNotify', { type: 'success', message: result.message})
					}else{
						this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
					}
				}catch(e){
					console.log(e)
				}finally {
					this.clear()
					this.$emit('offLoading')
				}
			}
			,toList: function(){
				this.$emit('push', {name: 'AgencyList', not_query: true})
			}

			,daumPost: function () {
				this.is_post = true
			}
			, addPost: function (call) {

				this.$set(this.item, 'zipCode', call.zonecode)
				this.$set(this.item, 'address', call.address)

				this.is_post = false
			}
      ,isDeleteNewFile: function(index){
        this.$delete(this.item.attachments, index)
      }
			,isDeleteFile: function(index){

				this.deleteIdxList.push(this.files[index].idx)
				this.$set(this.item, 'deleteIdxList', this.deleteIdxList)
				this.$delete(this.files, index)
			}
			,doFileDelete: async function(){
				try{
					this.$emit('onLoading')
					const result = await this.Axios({
						method: 'post'
						,url: ''
						,data: this.item
					})
					if(result.success){
						this.$emit('setNotify', { type: 'error', message: result.message})
					}else{
						this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
					}
				}catch(e){
					console.log(e)
				}finally {
					this.clear()
					this.$emit('offLoading')
				}
			}
			,download: function(file){
        console.log(file)
        console.log(file.path)
				this.is_modal5 = true
				this.item_att = file.path
			}
			, setUpper: function(item){

				if(item.idx) {
					this.$set(this.item, 'upperIdx', item.idx)
					this.item.upperName = item.name
				}
				this.clear()
			}
			,getFee: async function(){
				try{
					this.$emit('onLoading')
					const result = await this.Axios({
						method: 'get'
						,url: 'branch/modifyFee'
						,data: {
							adminIdx: this.item.idx
						}
					})
					if(result.success){
						this.upperFee = result.data.upperCurrentFee
						this.$set(this.item, 'upperFee', Number(Number(this.upperFee) - Number(this.item.fee)).toFixed(2))
						this.availableFee = result.data.upperAvailableFee
						this.lowerAvailableFee = result.data.lowerAvailableFee
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(e){
					console.log(e)
				}finally {
					this.$emit('offLoading')
				}
			}
			,resetUpper: function(){
				this.item.upperIdx = ''
				this.item.upperName = ''
			}
			,nextUpper: function(type, sub){
				this.upperIdx = sub.idx
				this.getAgencyList()
			}
			,checkFee: function(){
				if(this.lowerAvailableFee > this.item.fee){
					this.$emit('setNotify', { type: 'error', message: '상위 영업단 수익보다 높을수 없습니다'})
					this.item.fee = 0
					this.$set(this.item, 'upperFee', this.upperFee)
				}else if(this.availableFee < this.item.fee){
					this.$emit('setNotify', { type: 'error', message: '상위 영업단 수익보다 높을수 없습니다'})
					this.item.fee = 0
					this.$set(this.item, 'upperFee', this.upperFee)
				}else{
					this.$set(this.item, 'upperFee', Number(this.upperFee - this.item.fee).toFixed(2))
				}
			}
			,isModalMid: function(){
				this.clear()
				this.modal_mid = true
			}
			,isDeleteMid: function(){
				this.clear()
				this.modal_delete_mid = true
			}
			,doDeleteMid: async function(){
				try{
					this.$emit('onLoading')
					const result = await this.Axios({
						method: 'delete'
						,url: 'branch/mid/' + this.item.idx

					})
					if(result.success){
						await this.getData()
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(e){
					console.log(e)
				}finally {
					this.$emit('offLoading')
					this.clear()
				}
			}
			,doSaveMid: async function(){
				try{
					this.$emit('onLoading')
					const result = await this.Axios({
						method: 'put'
						,url: 'branch/mid/' + this.item.idx
						,data: {
							manualPaymentId: this.item.manualPaymentId
							, manualPaymentPassword: this.item.manualPaymentPassword
							, billKeyPaymentId: this.item.billKeyPaymentId
							, billKeyPaymentPassword: this.item.billKeyPaymentPassword
						}
					})
					if(result.success){
						await this.getData()
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(e){
					console.log(e)
				}finally {
					this.$emit('offLoading')
					this.clear()
				}
			}
			, getQuickAccount: async function(){
				try{
					this.$emit('onLoading')
					const result = await this.Axios({
						method: 'get'
						,url: 'quick/account_' + this.item.idx
						,data: {
							branchIdx: this.item.idx
						}
					})
					if(result.success){
						this.account = result.data
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(e){
					console.log(e)
				}finally {
					this.$emit('offLoading')
				}
			}
			,isModalIV: function(){
				this.clear()
				this.modal_iv = true
			}
			,isModalKey: function(){
				this.modal_key = true
			}
			, postAccount: async function(){
				try{
					this.$emit('onLoading')
					const result = await this.Axios({
						method: 'post'
						,url: 'quick/account_' + this.item.idx
						,data: {
							branchIdx: this.item.idx
						}
					})
					if(result.success){
						this.account = result.data
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(e){
					console.log(e)
				}finally {
					this.$emit('offLoading')
				}
			}
			, postIv: async function(){
				try{
					this.$emit('onLoading')
					const result = await this.Axios({
						method: 'put'
						,url: 'branch/keyIV/' + this.item.idx
						,data: {
							iv: this.item.iv
							, key: this.item.key
							, sid: this.item.sid
						}
					})
					if(result.success){
						this.$emit('setNotify', { type: 'success', message: result.message})
						this.modal_iv = false
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(e){
					console.log(e)
				}finally {
					this.$emit('offLoading')
				}
			}
		}
		, async created() {
			this.$emit('onLoad', this.program)
			await this.getData()
			await this.getBankList()
			if(this.item.type == 1){
				await this.getQuickAccount()
			}
		}
		,watch: {
			'item.upperIdx': {
				immediate: false
				,handler: function(){
					this.getFee()
				}
			}

			,'item.businessType': function(){
				this.item.businessNumber = this.item_origin.businessNumber
				this.item.corporationNumber = this.item_origin.corporationNumber
				this.item.frontSSN = this.item_origin.frontSSN
				this.item.backSSN = this.item_origin.backSSN
			}
		}
	}
</script>

<style>
	.width-fee { width: 60px !important; text-align: right;}
</style>